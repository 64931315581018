.Header {
	position:fixed;
	top:0;left:0;right:0;
	height:64px;
	border-bottom: 1px solid #D3D6E9;
	background-color:white;
	z-index:99;
}
.Header .App-logo {
	height:42px;
	display:inline-block;
	margin:11px 29px;
	transform:translateX(env(safe-area-inset-left));
}
.Header ul {
	display:block;
	height:64px;
	margin:0 auto;
	text-align:center;
}
.Header ul li {
	display:inline-block;
	padding:16px;
	margin:0 4px;
	border-top:5px solid transparent;
}
.Header ul li.current {
	border-top:5px solid #95D555;
}
.Header .btn-toolbar {
	/*min-width:98px;*/
	margin:16px 29px 0 0;
  justify-content: flex-end;
}
.Header .btn-toolbar a {
	border-top:5px solid transparent;
	display:inline-block;
}
.Header .btn-toolbar a.iconLink {
	border-top:none;
}
.Header .btn-toolbar .btn-link {
	padding:0;
}
.Header .btn-toolbar button:not(:last-child) {
	margin-right:12px;
}
.Header .NavToolbar .ProfileImage {
	flex:0 0 32px;
	width:32px;height:32px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.popover::before {
	content:' ';
	position: absolute;
    top: -100%;
    width: 200vw;
    height: 200vh;
    right: -10px;
	background-color:black;
	opacity:0.25;
	z-index:-1;
	pointer-events:none;
}
.UnseenPopup {
	min-width:300px;
	z-index:999;
	background-color:white;
}
.UnseenPopup .MessageList {
	padding-left:32px;
	max-height:300px;
}
.UnseenPopup .MessageLink {
	text-align:center;
	padding-bottom:12px;
}
.popover-body {
	padding:0;
    background-color: white;
}
.popover-body ul {
	list-style: none;
	padding:0;
	margin:0;
}
.popover-body ul li {
	min-width:200px;
	padding:10px 20px;
}
.popover-body ul a:hover li {
	background-color:#95D555;
}
.popover-body ul a:hover {
	color:white;
	text-decoration:none;
}

/* media queries */
@media (max-width: 767px) {
	.Header .App-logo {
		margin:11px 12px;
	}
	.Header .btn-toolbar {
		margin:16px 12px 0 0;
	}
	.Header ul {
		display:none;
	}
}