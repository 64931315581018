.SidePanelWrapper {
	width:0;
	height:auto;
	min-width:0;
	max-width:0;
	/*min-height:0;*/
	min-height: 85px;	/* This should be the height of SidePanel on mobile */
	max-height:calc(100vh - 64px);
	padding:0 0 24px;
	overflow:visible;
	overflow-y:auto;

	transform: translateZ(0);
	/*transition: min-width 400ms linear, max-width 400ms linear;*/
}

.SidePanelWrapper.visible {
	/*min-width: 280px;
	max-width: 380px;
	width: calc(20vw + 50px);*/
	min-width: 260px; /*calc(8vw + 150px);*/
	max-width: 340px;
	width:280px;
	min-height: 85px;	/* This should be the height of SidePanel on mobile */
	padding-right:24px;
}

.SidePanel {
	min-width: 260px; /*calc(8vw + 130px)/*100%*/
	/*max-width: 0;*/
	max-width: 100%;
	padding:18px;
	margin:18px 0 0 19px;

	background-color: #F8F8FA;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	transform: translate3d(-100%,0,0);
	opacity:0;
	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
}
.SidePanelWrapper.visible .SidePanel {
	transform: translate3d(0,0,0);
	opacity:1;
	max-width: 100%;
}

.SideProfile {
	display:flex;
	align-items: flex-start;
	flex-direction: row;
	margin-bottom:32px;
}
.SideProfileText {
	margin:0 0 0 10px;
	flex:1 1 0;
	min-width: 0;
}
.SideProfile .ProfileImage {
	flex:0 0 48px;
	width:48px;height:48px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.SideProfileText .ProfileName {
	display:block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.SideProfileText .ProfileType {
	display:block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	color: #999999;
}
.SideProfileText .ProfileLink {
	display:inline-block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 10px;
	text-decoration: none;
	border-bottom:1px dashed #95D555;
	color: #95D555;
	padding-bottom:2px;
}

.SidePanelGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-gap: 18px;
}

/* media queries */
@media (max-width: 767px) {
	.SidePanelWrapper {
		min-width:100vw !important;
		max-width:100vw !important;
		min-height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		max-height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		transition: none;
		padding-bottom:0;
		margin-bottom:6px;
		overflow:visible;
	}
	.SidePanel {
		position: -webkit-sticky;
		/*position:fixed;		/* NOTE: Delete to remove sticky button grid */
		/*top:64px;left:0;right:0;		/* NOTE: Delete to remove sticky button grid */
		/*z-index:1;		/* NOTE: Delete to remove sticky button grid */

		display: flex;
		flex-flow: column;
		/*padding:12px;
		margin:12px 12px 8px;
		min-width: calc(100vw - 24px) !important;
		max-width: calc(100vw - 24px) !important;
		width: calc(100vw - 24px) !important;*/
		/*background-color:transparent;*/
		padding:0;
		margin:0 0 4px;
		min-width:100vw !important;
		max-width:100vw !important;
		width:100vw !important;
		border-radius:0;
		transition: none;
	}
	.SideProfile {
		order: 2;
		margin-bottom:10px;
		padding:12px 12px 0;
		display:none;		/* NOTE: Remove to show profile pane on mobile */
	}
	.SidePanelServicesTitle {
		display:none;
	}
	.SidePanelGrid {
		order: 1;
		grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
		grid-gap: 0;
		border-bottom: 1px solid #D3D6E9;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	}
	.SidePanelGrid .SidePanelButton {
		border-radius:0;
	}
	.SidePanelWrapper.visible {
		min-width:calc(100% - 38px);
		padding-right:0;
		height:auto;
	}
	.SidePanelWrapper.visible .SidePanel {
		min-width:100%;
	}
	.SidePanelWrapper:not(.visible) {
		height:0 !important;
		min-height:0 !important;
		max-height:0 !important;
	}
}