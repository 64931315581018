.SimplePanelWrapper {
	/*display:inline-block;*/
	/*width:100%;*/
	/*min-width:300px;*/
	min-height:85px;
	margin:0 9px 18px;
	/*padding:0 18px 0 0;*/
	transform: translateZ(0);
}

.SimplePanelInner {
	width:100%;
	padding:13px 0 0;
	margin:0;

	border-radius: 10px;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
	transform: translateY(0px);

	background: #FFFFFF;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	overflow:hidden;
}

.SimplePanelContainer {
	padding:0 12px !important;
	display:flex;
	flex-direction: column;
}

.SimplePanelInner .titlerow {
	flex:0 0 auto;
	border-bottom:1px solid #DDDDDD;
	padding:0 5px;
}
.SimplePanelInner .headerrow {
	flex:0 0 32px;
	border-bottom:1px solid #DDDDDD;
	padding:0 5px;
}
.SimplePanelInner .childrow {
	flex:1 1 auto;
	padding:13px 18px;
	overflow:hidden;
	overflow-y:auto;
}
.SimplePanelInner .footerrow {
	flex:0 0 25px;
	padding:0 2px;
}
.SimplePanelInner .title {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: flex-end;
	
	color: 1px solid #DDDDDD;
	margin-bottom:13px;
}

/* media queries */
@media (max-width: 767px) {
	.SimplePanelWrapper {
		width:100%;
		padding:0;
		margin:0 0 18px;
	}
}