.activityBucket {
    margin-top:24px;
}
.activityBucket:first-of-type {
    margin-top:12px;
}
.activityBucketTitle {
    padding-bottom:12px;
}

/* media queries */
@media (max-width: 767px) {
    .activityContainer {
        padding:0;
    }
}