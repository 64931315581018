.ProcessPanelWrapper {
	/*display:inline-block;*/
	width:33.3%;
	min-width:280px;
	flex:1 1 33.3%;
	min-height:85px;
	padding:0 9px 18px;
	/*padding:0 18px 0 0;*/
	transform: translateZ(0);
}

.ProcessPanelInner {
	width:100%;
	padding:18px 24px;

	border-radius: 10px;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
	transform: translateY(0px);
}
/*.fade-enter.fade-exit-active .ProcessPanelInner {
	transform: translateY(0px);
	opacity:1;
}*/
.ProcessPanelInner .col:first-child {
	padding-left:12px !important;
	padding-right:0;
}
.ProcessPanelInner .col:last-child {
	padding-left:0;
	padding-right:12px !important;
}
.ProcessPanelInner .title {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	text-align:left;
	color: #FFFFFF;
	margin-bottom:20px;
}
.ProcessPanelInner .text {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	text-align:left;
	color: #FFFFFF;
	margin-bottom:0;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.ProcessPanelInner .percent {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 36px;
	text-align: right;
	color: #FFFFFF;
	margin-bottom:0;
}
.Donor .ProcessPanelInner {
	background: linear-gradient(180deg, #EC92B3 0%, #FBAD9C 100%), #C4C4C4;
	transition-delay:0ms;
}
.IVF .ProcessPanelInner {
	background: linear-gradient(180deg, #92CCEC 0%, #9CFBB1 100%), #C4C4C4;
	transition-delay:150ms;
}
.Surrogacy .ProcessPanelInner {
	background: linear-gradient(180deg, #C592EC 0%, #9E9CFB 100%), #C4C4C4;
	transition-delay:300ms;
}

/* media queries */
@media (max-width: 767px) {
	.ProcessPanelWrapper {
		width:100%;
		padding:0 0 18px;
	}
}