.MessageContainer {
    position:relative;
    /*margin-top:16px;*/
    padding:2px 9px 0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	transform: translateZ(0);
}
.MessageContainer .ImageColumn {
    position:relative;
    left:-8px;top:4px;
    flex:0 1 auto;
    white-space: nowrap;
    min-width:30px;
    margin:0;
    transform: translateY(-1px);
}
.MessageContainer .ProfileImage {
	flex:0 0 32px;
	width:32px;height:32px;
	min-width:32px;min-height:32px;
	max-width:32px;max-height:32px;
	border-radius:16px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.MessageRow {
    position:relative;
    flex:0 1 auto;
    /*min-width:200px;*/
    max-width:75%;
    min-height:28px;
    /*margin-bottom:12px;*/
    padding:4px;
    margin-left: 0;
    margin-right: 0;
    background-color:#eeeeee;
    border-radius:18px;
}
.MessageContainer .MessageRow {
    margin-top:0;
}
.MessageContainer.joined .MessageRow {
    margin-top:0;
}
.MessageContainer.unseen .MessageColumn:before {
    content:' ';
    position:absolute;
    right:-12px;top:-4px;
    width:8px;height:8px;
    background-color:#FFCE20;
    border-radius:4px;
}
.MessageOuter .MessageContainer .MessageRow:before {
    content: '';
    bottom: 3px;
    right: -14px;
    position: absolute;
    border: 0px solid;
    display: block;
    width: 20px;
    height: 13px;
    background-color: transparent;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
.HeaderRow {
    display:none;
}
.MessageOuter:first-child .HeaderRow {
    display:block;
}
.MessageOuter:last-child .MessageContainer.owner .MessageRow:before {
    bottom: -15px;
    right: 2px;
    width: 20px;height: 20px;
    /*transform: rotateZ(45deg);*/
    transform: scale3d(0.6,0.68,1) rotateZ(90deg);
    box-shadow: -10px -6px 0px 5px #95D555;
}
.MessageOuter:last-child .MessageContainer.recipient .MessageRow:before {
    top: 5px;
    left: -16px;
    transform: scale3d(0.6,0.68,1);
    box-shadow: 10px -6px 0px 5px #eeeeee;
}
.MessageRow .NameValue {
    position:absolute;
    color:#aaa;
    top:-20px;left:9px;
    padding:0;
    text-align:left;
    max-width:140px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.MessageRow .TimeValue {
    position:absolute;
    top:-20px;right:9px;
    padding:0;
    text-align:right;
}
.MessageRow .TimeValue time {
    display:inline-block;
    color:#aaa;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.MessageColumn {
    padding:0;
}
.MessageColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:4px 8px;
    line-height: 20px;
    white-space: pre-line;
}


.MessageContainer.owner .ImageColumn {
    display:none;
}
.MessageContainer.owner .MessageRow {
    margin-left:auto;
}
.MessageContainer.owner .MessageRow {
    background-color:#95D555;
}
.MessageContainer.owner .MessageRow .MessageColumn p {
    color:white;
}
.MessageContainer.owner .MessageRow .NameValue {
    display:none;
}
/*.MessageContainer.highlight .MessageRow {
    box-shadow:0 0 5px 1px #FFCE20;
}*/


/* media queries */
@media (max-width: 767px) {
    .MessageContainer {
        padding:4px 0 0;
    }
    .MessageContainer.recipient:not(.joined) {
        padding:35px 0 0;
    }
}