.CompanyPanelWrapper {
	/*display:inline-block;*/
	/*width:100%;*/
	/*min-width:300px;*/
	min-height:85px;
	margin:0 9px 18px;
	/*padding:0 18px 0 0;*/
}

.CompanyPanelInner {
	width:100%;
	padding:0 0;
	margin:0;

	border-radius: 10px;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
	transform: translateY(0px);

	background: #FFFFFF;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow:hidden;
}
.CompanyPanelInner .childrow {
	padding:0;
}
.CompanyPanelInner .childrow>*:first-child {
    padding:0;
}
.CompanyPanelInner .childrow .ImageContainer {
    position:relative;
    overflow:hidden;
	height:180px;
	min-height:100%;
}
.CompanyPanelInner .childrow .ImageContainer .sourceBadge {
    position:absolute;
    top:0;
    right:0;
    width:64px;
    height:64px;
}
.CompanyPanelInner .childrow .detailPane {
    padding:12px 12px;
}
.CompanyPanelInner .childrow .detailPane a {
	cursor: pointer !important;
}
.CompanyPanelInner .childrow .titlerow {
    padding:4px 8px 12px;
    border-bottom:1px solid #ddd;
}
.CompanyPanelInner .childrow .titlerow p {
    margin:0;
}
.CompanyPanelInner .childrow .detailrow {
    padding:18px 8px;
}
.CompanyPanelInner .childrow .detailrow p {
    margin:0;
}
.CompanyPanelInner .childrow .actionrow {
    padding:0;
}
.CompanyPanelInner .childrow .actioncolumn {
	text-align:right;
}
.CompanyPanelInner .childrow .actioncolumn button {
	margin-left:8px;
}

/* media queries */
@media (max-width: 767px) {
	.CompanyPanelWrapper {
		width:100%;
		padding:0;
		margin:0 0 18px;
	}
    .CompanyPanelInner .childrow .ImageContainer {
        height:160px;
    }
}