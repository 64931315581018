.taskBucket {
    margin-top:32px;
}
.taskBucket:first-of-type {
    margin-top:12px;
}
.taskBucketTitle {
    padding-bottom:12px;
}

/* media queries */
@media (max-width: 767px) {
}