.SidePanelButton {
	flex: 1 1 0;
	background-color:#FFFFFF;
	border-radius:10px;
	width:100%;
	min-width:90px;
	min-height:90px;
	/*margin:8px;*/
	padding:5px 0 0;
	border:2px solid transparent;
	transition:border 0.15s ease-out;
	grid-column-start:auto;
	grid-column-end:auto;
}
.SidePanelButton img {
	display:block;
	margin:0 auto;
}
.SidePanelButton span {
	display:block;
	margin:6px auto 0;

	font-family: Rounded Mplus 1c;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	/* identical to box height */
	text-align: center;

	color: #555555;
}

.SidePanelButton:hover {
	border:2px solid #95D555;
	transition:border 0s linear;
}
.SidePanelButton:focus {
  outline: none;
}

/* media queries */
@media (max-width: 767px) {
	.SidePanelButton {
		min-width:60px;
		min-height:63px;
	}
	.SidePanelButton img {
		width:24px;
		height:24px;
	}
	.SidePanelButton span {
		font-size: 10px;
	}
}