
.MessageList {
    /*margin-bottom:56px;*/
    min-height: calc(100vh - 210px);
    padding:12px 0 56px;
}
.MessageList.sm {
    min-height:unset;
	max-height:300px;
    margin-bottom:12px;
}
.MessageList.md {
    min-height:280px;
    max-height:280px;
    margin-bottom:12px;
}
.MessageDate {
    text-align:center;
    padding:24px 0;
}
.MessageDate::before, .MessageDate::after {
    content:' ';
    position:absolute;
    left:0;width:calc(50% - 70px);height:2px;
    border-bottom:1px solid #E5E5E5;
    padding-top:8px;
}
.MessageDate::after {
    left:unset;right:0;
}

@media (max-width: 767px) {
    .MessageList {
        padding-bottom:64px;
    }
    .MessageList.compact {
        padding-bottom:12px;
    }
    .MessageDate {
        text-align:center;
        padding:24px 0 0;
    }
}