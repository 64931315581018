.ActivityContainer {
    position:relative;
    padding-left:0;
    padding-right:0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	transform: translateZ(0);
}
.ActivityRow {
    flex:1 1 auto;
    height:32px;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 64px);
}
.ActivityColumn {
    padding:0;
}
.DotColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:64px;
}
.DotColumn .dot {
    position:relative;
    display:block;
    float:left;
    width:12px;
    height:12px;
    /*background-color:#95D555;*/
    border:2px solid #95D555;
    border-radius:6px;
    margin:10px;
}
.ActivityContainer:first-of-type .dot {
    background-color:#95D555;
}
.ActivityContainer:not(:first-of-type) .dot::before {
    position:absolute;
    content:' ';
    left:3px;bottom:10px;
    width:2px;
    height:16px;
    background-color:#95D555;
}
.ActivityContainer:not(:last-child) .dot::after {
    position:absolute;
    content:' ';
    left:3px;top:10px;
    width:2px;
    height:16px;
    background-color:#95D555;
}
.ActivityColumn img {
    display:inline-block;
    margin-right:12px;
}
.ActivityColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 8px;
    line-height:32px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.TimeColumn {
    padding:0;
    text-align:right;
}
.TimeColumn p {
    color:#aaa;
    line-height:32px;
}

/* media queries */
@media (max-width: 767px) {
}