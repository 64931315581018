@import url(https://fonts.googleapis.com/css?family=Cabin:400,400i,500,500i,600,600i,700,700i|M+PLUS+Rounded+1c:100,300,400,500,700,800,900&display=swap);
body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F3F3F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
	opacity: 0.01;
}
.fade-enter.fade-enter-active {
	opacity: 1;
	/*transition: opacity 650ms ease-in-out;*/
}
.fade-exit {
  opacity: 1;
  z-index:999;
}
.fade-exit.fade-exit-active {
	opacity: 0.01;
	-webkit-transition: opacity 0ms ease-in-out;
	transition: opacity 0ms ease-in-out;
}

div.transition-group {
	position: relative;
}
.fade-exit section.route-section {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}


.container-fluid:first-child {
  padding-left:0;
}
.container-fluid:last-child {
  padding-right:0;
}

.Main {
  position:absolute;
  top:64px;left:0;right:0;bottom:0;
  overflow:hidden;
  padding: 0 0 0 env(safe-area-inset-left);
}
.Wrapper {
  position:relative;
  height:100vh;
  max-height:calc(100vh - 63px);
  overflow:scroll;
  /*padding:1px;*/
}
.Content {
  position:absolute;
  top:0;left:0;right:0;bottom:0;
}

/* media queries */
@media (max-width: 767px) {
  body {
    touch-action: none;
  }
  .Main {
    /*height:100vh;   /* Remove to force-show the iOS Safari toolbar (to prevent double-click button tap requirement) */
    overflow:scroll;
    /*-webkit-overflow-scrolling:touch;*/
  }
  .Wrapper {
    overflow:visible;
    height:auto;
    width:100vw;
  }
  .MainContainer {
    max-width:100%;
    padding:0;
  }
}
a { color:#555555;text-decoration:none; }
a:visited { color:#555555;text-decoration:none; }
a:hover { color:#555555;text-decoration:none; }
a.simple { text-decoration:none; }
a.link { color:#999999;border-bottom:1px dashed #999999;text-decoration:none; }
a.primary { font-weight:bold;color:#95D555;border-bottom:1px dashed #95D555;text-decoration:none; }
button.btn-primary { color: #fff; background-color: #95D555; border-color: #95D555; }
button.btn-primary:disabled  { color: #ddd; background-color: #aaaaaa; border-color: #aaaaaa; }
button.btn-secondary { color: #fff; background-color: #aaaaaa; border-color: #aaaaaa; }
button:focus, input:focus { outline:none !important;box-shadow:none !important; }
.badge { font-size:100%; }
.badge.badge-primary { background-color: #95D555; border-color: #95D555; }
.light { color:#999999 }
.smaller { font-size:87%; }
.compact { margin:0; }

p, button {
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #555555;
}
p.small { font-size: 11px }
p.medium { font-size: 14px }
p.right { text-align:right; }
h2 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #555555;
}
h3 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #777777;
}
h4 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	color: #777777; 
}
h4.primary { color:#95D555; }
.Content {
	padding:18px 18px 24px;
}

@media (max-width: 767px) {
    .Content {
        padding:9px;
	}
}
.Header {
	position:fixed;
	top:0;left:0;right:0;
	height:64px;
	border-bottom: 1px solid #D3D6E9;
	background-color:white;
	z-index:99;
}
.Header .App-logo {
	height:42px;
	display:inline-block;
	margin:11px 29px;
	-webkit-transform:translateX(env(safe-area-inset-left));
	        transform:translateX(env(safe-area-inset-left));
}
.Header ul {
	display:block;
	height:64px;
	margin:0 auto;
	text-align:center;
}
.Header ul li {
	display:inline-block;
	padding:16px;
	margin:0 4px;
	border-top:5px solid transparent;
}
.Header ul li.current {
	border-top:5px solid #95D555;
}
.Header .btn-toolbar {
	/*min-width:98px;*/
	margin:16px 29px 0 0;
  justify-content: flex-end;
}
.Header .btn-toolbar a {
	border-top:5px solid transparent;
	display:inline-block;
}
.Header .btn-toolbar a.iconLink {
	border-top:none;
}
.Header .btn-toolbar .btn-link {
	padding:0;
}
.Header .btn-toolbar button:not(:last-child) {
	margin-right:12px;
}
.Header .NavToolbar .ProfileImage {
	flex:0 0 32px;
	width:32px;height:32px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.popover::before {
	content:' ';
	position: absolute;
    top: -100%;
    width: 200vw;
    height: 200vh;
    right: -10px;
	background-color:black;
	opacity:0.25;
	z-index:-1;
	pointer-events:none;
}
.UnseenPopup {
	min-width:300px;
	z-index:999;
	background-color:white;
}
.UnseenPopup .MessageList {
	padding-left:32px;
	max-height:300px;
}
.UnseenPopup .MessageLink {
	text-align:center;
	padding-bottom:12px;
}
.popover-body {
	padding:0;
    background-color: white;
}
.popover-body ul {
	list-style: none;
	padding:0;
	margin:0;
}
.popover-body ul li {
	min-width:200px;
	padding:10px 20px;
}
.popover-body ul a:hover li {
	background-color:#95D555;
}
.popover-body ul a:hover {
	color:white;
	text-decoration:none;
}

/* media queries */
@media (max-width: 767px) {
	.Header .App-logo {
		margin:11px 12px;
	}
	.Header .btn-toolbar {
		margin:16px 12px 0 0;
	}
	.Header ul {
		display:none;
	}
}

.MessageList {
    /*margin-bottom:56px;*/
    min-height: calc(100vh - 210px);
    padding:12px 0 56px;
}
.MessageList.sm {
    min-height:unset;
	max-height:300px;
    margin-bottom:12px;
}
.MessageList.md {
    min-height:280px;
    max-height:280px;
    margin-bottom:12px;
}
.MessageDate {
    text-align:center;
    padding:24px 0;
}
.MessageDate::before, .MessageDate::after {
    content:' ';
    position:absolute;
    left:0;width:calc(50% - 70px);height:2px;
    border-bottom:1px solid #E5E5E5;
    padding-top:8px;
}
.MessageDate::after {
    left:unset;right:0;
}

@media (max-width: 767px) {
    .MessageList {
        padding-bottom:64px;
    }
    .MessageList.compact {
        padding-bottom:12px;
    }
    .MessageDate {
        text-align:center;
        padding:24px 0 0;
    }
}
.MessageContainer {
    position:relative;
    /*margin-top:16px;*/
    padding:2px 9px 0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
}
.MessageContainer .ImageColumn {
    position:relative;
    left:-8px;top:4px;
    flex:0 1 auto;
    white-space: nowrap;
    min-width:30px;
    margin:0;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}
.MessageContainer .ProfileImage {
	flex:0 0 32px;
	width:32px;height:32px;
	min-width:32px;min-height:32px;
	max-width:32px;max-height:32px;
	border-radius:16px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.MessageRow {
    position:relative;
    flex:0 1 auto;
    /*min-width:200px;*/
    max-width:75%;
    min-height:28px;
    /*margin-bottom:12px;*/
    padding:4px;
    margin-left: 0;
    margin-right: 0;
    background-color:#eeeeee;
    border-radius:18px;
}
.MessageContainer .MessageRow {
    margin-top:0;
}
.MessageContainer.joined .MessageRow {
    margin-top:0;
}
.MessageContainer.unseen .MessageColumn:before {
    content:' ';
    position:absolute;
    right:-12px;top:-4px;
    width:8px;height:8px;
    background-color:#FFCE20;
    border-radius:4px;
}
.MessageOuter .MessageContainer .MessageRow:before {
    content: '';
    bottom: 3px;
    right: -14px;
    position: absolute;
    border: 0px solid;
    display: block;
    width: 20px;
    height: 13px;
    background-color: transparent;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
.HeaderRow {
    display:none;
}
.MessageOuter:first-child .HeaderRow {
    display:block;
}
.MessageOuter:last-child .MessageContainer.owner .MessageRow:before {
    bottom: -15px;
    right: 2px;
    width: 20px;height: 20px;
    /*transform: rotateZ(45deg);*/
    -webkit-transform: scale3d(0.6,0.68,1) rotateZ(90deg);
            transform: scale3d(0.6,0.68,1) rotateZ(90deg);
    box-shadow: -10px -6px 0px 5px #95D555;
}
.MessageOuter:last-child .MessageContainer.recipient .MessageRow:before {
    top: 5px;
    left: -16px;
    -webkit-transform: scale3d(0.6,0.68,1);
            transform: scale3d(0.6,0.68,1);
    box-shadow: 10px -6px 0px 5px #eeeeee;
}
.MessageRow .NameValue {
    position:absolute;
    color:#aaa;
    top:-20px;left:9px;
    padding:0;
    text-align:left;
    max-width:140px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.MessageRow .TimeValue {
    position:absolute;
    top:-20px;right:9px;
    padding:0;
    text-align:right;
}
.MessageRow .TimeValue time {
    display:inline-block;
    color:#aaa;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.MessageColumn {
    padding:0;
}
.MessageColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:4px 8px;
    line-height: 20px;
    white-space: pre-line;
}


.MessageContainer.owner .ImageColumn {
    display:none;
}
.MessageContainer.owner .MessageRow {
    margin-left:auto;
}
.MessageContainer.owner .MessageRow {
    background-color:#95D555;
}
.MessageContainer.owner .MessageRow .MessageColumn p {
    color:white;
}
.MessageContainer.owner .MessageRow .NameValue {
    display:none;
}
/*.MessageContainer.highlight .MessageRow {
    box-shadow:0 0 5px 1px #FFCE20;
}*/


/* media queries */
@media (max-width: 767px) {
    .MessageContainer {
        padding:4px 0 0;
    }
    .MessageContainer.recipient:not(.joined) {
        padding:35px 0 0;
    }
}
.SidePanelWrapper {
	width:0;
	height:auto;
	min-width:0;
	max-width:0;
	/*min-height:0;*/
	min-height: 85px;	/* This should be the height of SidePanel on mobile */
	max-height:calc(100vh - 64px);
	padding:0 0 24px;
	overflow:visible;
	overflow-y:auto;

	-webkit-transform: translateZ(0);

	        transform: translateZ(0);
	/*transition: min-width 400ms linear, max-width 400ms linear;*/
}

.SidePanelWrapper.visible {
	/*min-width: 280px;
	max-width: 380px;
	width: calc(20vw + 50px);*/
	min-width: 260px; /*calc(8vw + 150px);*/
	max-width: 340px;
	width:280px;
	min-height: 85px;	/* This should be the height of SidePanel on mobile */
	padding-right:24px;
}

.SidePanel {
	min-width: 260px; /*calc(8vw + 130px)/*100%*/
	/*max-width: 0;*/
	max-width: 100%;
	padding:18px;
	margin:18px 0 0 19px;

	background-color: #F8F8FA;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	-webkit-transform: translate3d(-100%,0,0);

	        transform: translate3d(-100%,0,0);
	opacity:0;
	-webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
}
.SidePanelWrapper.visible .SidePanel {
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
	opacity:1;
	max-width: 100%;
}

.SideProfile {
	display:flex;
	align-items: flex-start;
	flex-direction: row;
	margin-bottom:32px;
}
.SideProfileText {
	margin:0 0 0 10px;
	flex:1 1;
	min-width: 0;
}
.SideProfile .ProfileImage {
	flex:0 0 48px;
	width:48px;height:48px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.SideProfileText .ProfileName {
	display:block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.SideProfileText .ProfileType {
	display:block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	color: #999999;
}
.SideProfileText .ProfileLink {
	display:inline-block;
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 10px;
	text-decoration: none;
	border-bottom:1px dashed #95D555;
	color: #95D555;
	padding-bottom:2px;
}

.SidePanelGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-gap: 18px;
}

/* media queries */
@media (max-width: 767px) {
	.SidePanelWrapper {
		min-width:100vw !important;
		max-width:100vw !important;
		min-height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		max-height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		height:66px !important;		/* NOTE: Remove to show profile pane on mobile */
		-webkit-transition: none;
		transition: none;
		padding-bottom:0;
		margin-bottom:6px;
		overflow:visible;
	}
	.SidePanel {
		position: -webkit-sticky;
		/*position:fixed;		/* NOTE: Delete to remove sticky button grid */
		/*top:64px;left:0;right:0;		/* NOTE: Delete to remove sticky button grid */
		/*z-index:1;		/* NOTE: Delete to remove sticky button grid */

		display: flex;
		flex-flow: column;
		/*padding:12px;
		margin:12px 12px 8px;
		min-width: calc(100vw - 24px) !important;
		max-width: calc(100vw - 24px) !important;
		width: calc(100vw - 24px) !important;*/
		/*background-color:transparent;*/
		padding:0;
		margin:0 0 4px;
		min-width:100vw !important;
		max-width:100vw !important;
		width:100vw !important;
		border-radius:0;
		-webkit-transition: none;
		transition: none;
	}
	.SideProfile {
		order: 2;
		margin-bottom:10px;
		padding:12px 12px 0;
		display:none;		/* NOTE: Remove to show profile pane on mobile */
	}
	.SidePanelServicesTitle {
		display:none;
	}
	.SidePanelGrid {
		order: 1;
		grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
		grid-gap: 0;
		border-bottom: 1px solid #D3D6E9;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	}
	.SidePanelGrid .SidePanelButton {
		border-radius:0;
	}
	.SidePanelWrapper.visible {
		min-width:calc(100% - 38px);
		padding-right:0;
		height:auto;
	}
	.SidePanelWrapper.visible .SidePanel {
		min-width:100%;
	}
	.SidePanelWrapper:not(.visible) {
		height:0 !important;
		min-height:0 !important;
		max-height:0 !important;
	}
}
.SidePanelButton {
	flex: 1 1;
	background-color:#FFFFFF;
	border-radius:10px;
	width:100%;
	min-width:90px;
	min-height:90px;
	/*margin:8px;*/
	padding:5px 0 0;
	border:2px solid transparent;
	-webkit-transition:border 0.15s ease-out;
	transition:border 0.15s ease-out;
	grid-column-start:auto;
	grid-column-end:auto;
}
.SidePanelButton img {
	display:block;
	margin:0 auto;
}
.SidePanelButton span {
	display:block;
	margin:6px auto 0;

	font-family: Rounded Mplus 1c;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	/* identical to box height */
	text-align: center;

	color: #555555;
}

.SidePanelButton:hover {
	border:2px solid #95D555;
	-webkit-transition:border 0s linear;
	transition:border 0s linear;
}
.SidePanelButton:focus {
  outline: none;
}

/* media queries */
@media (max-width: 767px) {
	.SidePanelButton {
		min-width:60px;
		min-height:63px;
	}
	.SidePanelButton img {
		width:24px;
		height:24px;
	}
	.SidePanelButton span {
		font-size: 10px;
	}
}


.SimplePanelWrapper {
	/*display:inline-block;*/
	/*width:100%;*/
	/*min-width:300px;*/
	min-height:85px;
	margin:0 9px 18px;
	/*padding:0 18px 0 0;*/
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
}

.SimplePanelInner {
	width:100%;
	padding:13px 0 0;
	margin:0;

	border-radius: 10px;

	-webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);

	background: #FFFFFF;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	overflow:hidden;
}

.SimplePanelContainer {
	padding:0 12px !important;
	display:flex;
	flex-direction: column;
}

.SimplePanelInner .titlerow {
	flex:0 0 auto;
	border-bottom:1px solid #DDDDDD;
	padding:0 5px;
}
.SimplePanelInner .headerrow {
	flex:0 0 32px;
	border-bottom:1px solid #DDDDDD;
	padding:0 5px;
}
.SimplePanelInner .childrow {
	flex:1 1 auto;
	padding:13px 18px;
	overflow:hidden;
	overflow-y:auto;
}
.SimplePanelInner .footerrow {
	flex:0 0 25px;
	padding:0 2px;
}
.SimplePanelInner .title {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: flex-end;
	
	color: 1px solid #DDDDDD;
	margin-bottom:13px;
}

/* media queries */
@media (max-width: 767px) {
	.SimplePanelWrapper {
		width:100%;
		padding:0;
		margin:0 0 18px;
	}
}



@media (max-width: 767px) {
    .Content>.container {
        padding:0;
    }
    .OnboardingContainer {
        padding:0;
    }
}
.UnconfirmedContainer {
	width:100%;
	padding:0;
}

/* media queries */
@media (max-width: 767px) {
	.UnconfirmedContainer {
		width:100%;
		/*max-width:100%;*/
		padding:0;
	}
}

.DashboardContainer {
	width:100%;
	padding:0;
}

/* media queries */
@media (max-width: 767px) {
	.DashboardContainer {
		width:100%;
		/*max-width:100%;*/
		padding:0;
	}
}
.ProcessPanelWrapper {
	/*display:inline-block;*/
	width:33.3%;
	min-width:280px;
	flex:1 1 33.3%;
	min-height:85px;
	padding:0 9px 18px;
	/*padding:0 18px 0 0;*/
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
}

.ProcessPanelInner {
	width:100%;
	padding:18px 24px;

	border-radius: 10px;

	-webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
}
/*.fade-enter.fade-exit-active .ProcessPanelInner {
	transform: translateY(0px);
	opacity:1;
}*/
.ProcessPanelInner .col:first-child {
	padding-left:12px !important;
	padding-right:0;
}
.ProcessPanelInner .col:last-child {
	padding-left:0;
	padding-right:12px !important;
}
.ProcessPanelInner .title {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	text-align:left;
	color: #FFFFFF;
	margin-bottom:20px;
}
.ProcessPanelInner .text {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	text-align:left;
	color: #FFFFFF;
	margin-bottom:0;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.ProcessPanelInner .percent {
	font-family: Cabin, Helvetica;
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 36px;
	text-align: right;
	color: #FFFFFF;
	margin-bottom:0;
}
.Donor .ProcessPanelInner {
	background: -webkit-gradient(linear, left top, left bottom, from(#EC92B3), to(#FBAD9C)), #C4C4C4;
	background: -webkit-linear-gradient(top, #EC92B3 0%, #FBAD9C 100%), #C4C4C4;
	background: linear-gradient(180deg, #EC92B3 0%, #FBAD9C 100%), #C4C4C4;
	-webkit-transition-delay:0ms;
	        transition-delay:0ms;
}
.IVF .ProcessPanelInner {
	background: -webkit-gradient(linear, left top, left bottom, from(#92CCEC), to(#9CFBB1)), #C4C4C4;
	background: -webkit-linear-gradient(top, #92CCEC 0%, #9CFBB1 100%), #C4C4C4;
	background: linear-gradient(180deg, #92CCEC 0%, #9CFBB1 100%), #C4C4C4;
	-webkit-transition-delay:150ms;
	        transition-delay:150ms;
}
.Surrogacy .ProcessPanelInner {
	background: -webkit-gradient(linear, left top, left bottom, from(#C592EC), to(#9E9CFB)), #C4C4C4;
	background: -webkit-linear-gradient(top, #C592EC 0%, #9E9CFB 100%), #C4C4C4;
	background: linear-gradient(180deg, #C592EC 0%, #9E9CFB 100%), #C4C4C4;
	-webkit-transition-delay:300ms;
	        transition-delay:300ms;
}

/* media queries */
@media (max-width: 767px) {
	.ProcessPanelWrapper {
		width:100%;
		padding:0 0 18px;
	}
}

.taskBucket {
    margin-top:32px;
}
.taskBucket:first-of-type {
    margin-top:12px;
}
.taskBucketTitle {
    padding-bottom:12px;
}

/* media queries */
@media (max-width: 767px) {
}
.TaskContainer {
    position:relative;
    padding-left:0;
    padding-right:0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
}
.TaskRow {
    flex:1 1 auto;
    height:16px;
    margin-bottom:12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 24px);
}
.TaskColumn {
    padding:0;
}
.CheckColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:24px;
}
.TaskColumn .check {
    position:relative;
    display:inline-block;
}
.TaskContainer:first-of-type .dot {
    background-color:#95D555;
}
.TaskColumn input[type="checkbox"] {
    display:inline-block;
    margin-left:0;
    /*margin-right:12px;*/
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
.TaskColumn img {
    display:inline-block;
    width:22px;
    height:22px;
    margin-left:0;
    /*margin-right:4px;*/
    -webkit-transform:translateX(-2px);
            transform:translateX(-2px);
}
.TaskColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 4px;
    line-height:24px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.TimeColumn {
    padding:0;
    text-align:right;
}
.TimeColumn p time {
    display:inline-block;
    color:#ccc;
    line-height:24px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}

/* media queries */
@media (max-width: 767px) {
}
.activityBucket {
    margin-top:24px;
}
.activityBucket:first-of-type {
    margin-top:12px;
}
.activityBucketTitle {
    padding-bottom:12px;
}

/* media queries */
@media (max-width: 767px) {
}
.ActivityContainer {
    position:relative;
    padding-left:0;
    padding-right:0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
}
.ActivityRow {
    flex:1 1 auto;
    height:32px;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 64px);
}
.ActivityColumn {
    padding:0;
}
.DotColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:64px;
}
.DotColumn .dot {
    position:relative;
    display:block;
    float:left;
    width:12px;
    height:12px;
    /*background-color:#95D555;*/
    border:2px solid #95D555;
    border-radius:6px;
    margin:10px;
}
.ActivityContainer:first-of-type .dot {
    background-color:#95D555;
}
.ActivityContainer:not(:first-of-type) .dot::before {
    position:absolute;
    content:' ';
    left:3px;bottom:10px;
    width:2px;
    height:16px;
    background-color:#95D555;
}
.ActivityContainer:not(:last-child) .dot::after {
    position:absolute;
    content:' ';
    left:3px;top:10px;
    width:2px;
    height:16px;
    background-color:#95D555;
}
.ActivityColumn img {
    display:inline-block;
    margin-right:12px;
}
.ActivityColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 8px;
    line-height:32px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.TimeColumn {
    padding:0;
    text-align:right;
}
.TimeColumn p {
    color:#aaa;
    line-height:32px;
}

/* media queries */
@media (max-width: 767px) {
}
.ProfileContainer {
	width:100%;
	padding:0;
    margin-top:32px;
}
.ProfileContainer .SimplePanelWrapper {
    margin:0 0 18px;
}
.ProfileContainer .form-group {
    padding-left:0;
}
.ProfileHeader {
    margin-top:32px;
    text-align:center;
}
.ProfileHeader .ProfileImage {
    margin:0 auto 18px;
}
.ProfileHeader .ProfileName, .ProfileHeader .ProfileType {
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.PrivateMessagePanel .childrow {
    padding:0 !important;
}
.PrivateMessagePanel .footerrow {
	box-shadow: 0px -15px 3px #fff;
}
.PrivateMessagePanel .footerrow input[type="text"] {
    border:none;
    border-top:1px solid #DDDDDD;
}

/* media queries */
@media (max-width: 767px) {
	.ProfileContainer {
		width:100%;
		/*max-width:100%;*/
		padding:0;
	}
}



/* media queries */
@media (max-width: 767px) {
    .searchContainer {
        padding:0;
    }
}
.CompanyPanelWrapper {
	/*display:inline-block;*/
	/*width:100%;*/
	/*min-width:300px;*/
	min-height:85px;
	margin:0 9px 18px;
	/*padding:0 18px 0 0;*/
}

.CompanyPanelInner {
	width:100%;
	padding:0 0;
	margin:0;

	border-radius: 10px;

	-webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;

	transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);

	background: #FFFFFF;
	box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow:hidden;
}
.CompanyPanelInner .childrow {
	padding:0;
}
.CompanyPanelInner .childrow>*:first-child {
    padding:0;
}
.CompanyPanelInner .childrow .ImageContainer {
    position:relative;
    overflow:hidden;
	height:180px;
	min-height:100%;
}
.CompanyPanelInner .childrow .ImageContainer .sourceBadge {
    position:absolute;
    top:0;
    right:0;
    width:64px;
    height:64px;
}
.CompanyPanelInner .childrow .detailPane {
    padding:12px 12px;
}
.CompanyPanelInner .childrow .detailPane a {
	cursor: pointer !important;
}
.CompanyPanelInner .childrow .titlerow {
    padding:4px 8px 12px;
    border-bottom:1px solid #ddd;
}
.CompanyPanelInner .childrow .titlerow p {
    margin:0;
}
.CompanyPanelInner .childrow .detailrow {
    padding:18px 8px;
}
.CompanyPanelInner .childrow .detailrow p {
    margin:0;
}
.CompanyPanelInner .childrow .actionrow {
    padding:0;
}
.CompanyPanelInner .childrow .actioncolumn {
	text-align:right;
}
.CompanyPanelInner .childrow .actioncolumn button {
	margin-left:8px;
}

/* media queries */
@media (max-width: 767px) {
	.CompanyPanelWrapper {
		width:100%;
		padding:0;
		margin:0 0 18px;
	}
    .CompanyPanelInner .childrow .ImageContainer {
        height:160px;
    }
}
.activityBucket {
    margin-top:24px;
}
.activityBucket:first-of-type {
    margin-top:12px;
}
.activityBucketTitle {
    padding-bottom:12px;
}

/* media queries */
@media (max-width: 767px) {
    .activityContainer {
        padding:0;
    }
}
.ThreadList {
    margin:0 9px 0;
}
.ComposeRow {
    margin:0 0 12px;
    text-align:left;
}
.ThreadOuter {
    cursor:pointer;
}
.MessagePanel {
    position:relative;
    height: 100%;
    min-height: calc(100vh - 128px);
    max-height: calc(100vh - 128px);
}
.MessagePanel .SimplePanelWrapper, .MessagePanel .SimplePanelInner {
    position:relative;
    height: 100%;
    padding:0;
}
.ConversationPanel {
    /*height: calc(100% - 48px);*/
    max-height: 100%;
    background-color:#F8F8FA;
    box-sizing: border-box;
}
.ConversationPanel .childrow {
    padding-top:0;
    max-height: 100%;
    overflow:hidden;
    overflow-y:auto;
}
.ConversationPanel .headerrow .badge {
    margin-right:4px;
}
.InputPanel {
    position:absolute;
    margin:0;
    left:9px;bottom:0;right:9px;
}
.InputPanel .InputPanelGroup {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
}
.MessagePanelHeader {
    display:none;
}

@media (max-width: 767px) {
    .ThreadList {
        margin:0 0 9px;
    }
    .MessagePanel {
        position:relative;
        max-height: calc(100% - 64px);
    }
    .MessageList {
        /*margin-bottom:64px;*/
    }
    .InputPanel {
        left:0;
        right:0;
    }
    .InputPanel button, .InputPanel input {
        border-radius:0;
    }
    .MessagePanel.collapsed {
        display:none;
    }
    .MessagePanel.expanded {
        background-color:#FFFFFF;
        position:fixed;
        left:0;right:0;
        top:64px;bottom:0;
    }
    .MessagePanel.expanded .MessagePanelHeader {
        display:block;
        position:fixed;
        left:0;right:0;
        top:64px;height:48px;
        background-color:#FFFFFF;
        padding:12px 18px;
        cursor:pointer;
    }
    .MessagePanel.expanded .MessagePanelHeader p {
        line-height:24px;
        font-size:20px;
        text-align:right;
    }
    .MessagePanel.expanded .ConversationPanel {
        position:fixed;
        left:0;right:0;
        top:48px;bottom:0;
        border-top: 1px solid #D3D6E9;
        padding-top: 8px !important;
    }
    .MessagePanel.expanded .ConversationPanel .childrow {
        overflow:hidden;
        overflow-y:scroll;
    }
}
.ThreadContainer {
    position:relative;
    padding:9px 9px 0;
    margin:0 0 9px;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);

    background-color:#ffffff;
    border:1px solid #eeeeee;
}
.ThreadContainer.active {
    border-left:5px solid #95D555;
}
.ThreadContainer .ImageColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:56px;
    margin-left:12px;
}
.ThreadContainer .ProfileImage {
	flex:0 0 48px;
	width:48px;height:48px;
	min-width:48px;min-height:48px;
	max-width:48px;max-height:48px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.ThreadRow {
    flex:1 1 auto;
    height:48px;
    margin-bottom:12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 54px);
}
.ThreadColumn {
    padding:0;
}
.ThreadColumn .check {
    position:relative;
    display:inline-block;
}
.ThreadContainer:first-of-type .dot {
    background-color:#95D555;
}
.ThreadColumn input[type="checkbox"] {
    display:inline-block;
    margin-left:0;
    /*margin-right:12px;*/
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
.ThreadColumn img {
    display:inline-block;
    width:22px;
    height:22px;
    margin-left:0;
    /*margin-right:4px;*/
    -webkit-transform:translateX(-2px);
            transform:translateX(-2px);
}
.ThreadColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 4px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    line-height: 20px;
}
.ThreadColumn p.OwnerName {
    font-weight:400;
    max-width:60%;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.ThreadColumn p.ThreadMessage {
    color:#aaa;
}
.TimeValue {
    position:absolute;
    top:0;right:9px;
    padding:0;
    text-align:right;
}
.TimeValue time {
    display:inline-block;
    color:#ccc;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}

/* media queries */
@media (max-width: 767px) {
}
