.ThreadContainer {
    position:relative;
    padding:9px 9px 0;
    margin:0 0 9px;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	transform: translateZ(0);

    background-color:#ffffff;
    border:1px solid #eeeeee;
}
.ThreadContainer.active {
    border-left:5px solid #95D555;
}
.ThreadContainer .ImageColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:56px;
    margin-left:12px;
}
.ThreadContainer .ProfileImage {
	flex:0 0 48px;
	width:48px;height:48px;
	min-width:48px;min-height:48px;
	max-width:48px;max-height:48px;
	border-radius:24px;
	background-color:#EEE;
	/*box-shadow: 0 0 0 1px #EEE inset, 0 0 0 1px #EEE;*/
}
.ThreadRow {
    flex:1 1 auto;
    height:48px;
    margin-bottom:12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 54px);
}
.ThreadColumn {
    padding:0;
}
.ThreadColumn .check {
    position:relative;
    display:inline-block;
}
.ThreadContainer:first-of-type .dot {
    background-color:#95D555;
}
.ThreadColumn input[type="checkbox"] {
    display:inline-block;
    margin-left:0;
    /*margin-right:12px;*/
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
.ThreadColumn img {
    display:inline-block;
    width:22px;
    height:22px;
    margin-left:0;
    /*margin-right:4px;*/
    transform:translateX(-2px);
}
.ThreadColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 4px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    line-height: 20px;
}
.ThreadColumn p.OwnerName {
    font-weight:400;
    max-width:60%;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.ThreadColumn p.ThreadMessage {
    color:#aaa;
}
.TimeValue {
    position:absolute;
    top:0;right:9px;
    padding:0;
    text-align:right;
}
.TimeValue time {
    display:inline-block;
    color:#ccc;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}

/* media queries */
@media (max-width: 767px) {
}