.ProfileContainer {
	width:100%;
	padding:0;
    margin-top:32px;
}
.ProfileContainer .SimplePanelWrapper {
    margin:0 0 18px;
}
.ProfileContainer .form-group {
    padding-left:0;
}
.ProfileHeader {
    margin-top:32px;
    text-align:center;
}
.ProfileHeader .ProfileImage {
    margin:0 auto 18px;
}
.ProfileHeader .ProfileName, .ProfileHeader .ProfileType {
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.PrivateMessagePanel .childrow {
    padding:0 !important;
}
.PrivateMessagePanel .footerrow {
	box-shadow: 0px -15px 3px #fff;
}
.PrivateMessagePanel .footerrow input[type="text"] {
    border:none;
    border-top:1px solid #DDDDDD;
}

/* media queries */
@media (max-width: 767px) {
	.ProfileContainer {
		width:100%;
		/*max-width:100%;*/
		padding:0;
	}
}