.TaskContainer {
    position:relative;
    padding-left:0;
    padding-right:0;
    display:flex;
    flex-wrap: nowrap;
    align-items: stretch;
	transform: translateZ(0);
}
.TaskRow {
    flex:1 1 auto;
    height:16px;
    margin-bottom:12px;
    margin-left: 0;
    margin-right: 0;
    max-width:calc(100% - 24px);
}
.TaskColumn {
    padding:0;
}
.CheckColumn {
    flex:0 1 auto;
    white-space: nowrap;
    min-width:24px;
}
.TaskColumn .check {
    position:relative;
    display:inline-block;
}
.TaskContainer:first-of-type .dot {
    background-color:#95D555;
}
.TaskColumn input[type="checkbox"] {
    display:inline-block;
    margin-left:0;
    /*margin-right:12px;*/
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
.TaskColumn img {
    display:inline-block;
    width:22px;
    height:22px;
    margin-left:0;
    /*margin-right:4px;*/
    transform:translateX(-2px);
}
.TaskColumn p {
    display:block;
    max-width:100%;
    margin:0;
    padding:0 0 0 4px;
    line-height:24px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
.TimeColumn {
    padding:0;
    text-align:right;
}
.TimeColumn p time {
    display:inline-block;
    color:#ccc;
    line-height:24px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}

/* media queries */
@media (max-width: 767px) {
}