.fade-enter {
	opacity: 0.01;
}
.fade-enter.fade-enter-active {
	opacity: 1;
	/*transition: opacity 650ms ease-in-out;*/
}
.fade-exit {
  opacity: 1;
  z-index:999;
}
.fade-exit.fade-exit-active {
	opacity: 0.01;
	transition: opacity 0ms ease-in-out;
}

div.transition-group {
	position: relative;
}
.fade-exit section.route-section {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}


.container-fluid:first-child {
  padding-left:0;
}
.container-fluid:last-child {
  padding-right:0;
}

.Main {
  position:absolute;
  top:64px;left:0;right:0;bottom:0;
  overflow:hidden;
  padding: 0 0 0 env(safe-area-inset-left);
}
.Wrapper {
  position:relative;
  height:100vh;
  max-height:calc(100vh - 63px);
  overflow:scroll;
  /*padding:1px;*/
}
.Content {
  position:absolute;
  top:0;left:0;right:0;bottom:0;
}

/* media queries */
@media (max-width: 767px) {
  body {
    touch-action: none;
  }
  .Main {
    /*height:100vh;   /* Remove to force-show the iOS Safari toolbar (to prevent double-click button tap requirement) */
    overflow:scroll;
    /*-webkit-overflow-scrolling:touch;*/
  }
  .Wrapper {
    overflow:visible;
    height:auto;
    width:100vw;
  }
  .MainContainer {
    max-width:100%;
    padding:0;
  }
}