.ThreadList {
    margin:0 9px 0;
}
.ComposeRow {
    margin:0 0 12px;
    text-align:left;
}
.ThreadOuter {
    cursor:pointer;
}
.MessagePanel {
    position:relative;
    height: 100%;
    min-height: calc(100vh - 128px);
    max-height: calc(100vh - 128px);
}
.MessagePanel .SimplePanelWrapper, .MessagePanel .SimplePanelInner {
    position:relative;
    height: 100%;
    padding:0;
}
.ConversationPanel {
    /*height: calc(100% - 48px);*/
    max-height: 100%;
    background-color:#F8F8FA;
    box-sizing: border-box;
}
.ConversationPanel .childrow {
    padding-top:0;
    max-height: 100%;
    overflow:hidden;
    overflow-y:auto;
}
.ConversationPanel .headerrow .badge {
    margin-right:4px;
}
.InputPanel {
    position:absolute;
    margin:0;
    left:9px;bottom:0;right:9px;
}
.InputPanel .InputPanelGroup {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
}
.MessagePanelHeader {
    display:none;
}

@media (max-width: 767px) {
    .ThreadList {
        margin:0 0 9px;
    }
    .MessagePanel {
        position:relative;
        max-height: calc(100% - 64px);
    }
    .MessageList {
        /*margin-bottom:64px;*/
    }
    .InputPanel {
        left:0;
        right:0;
    }
    .InputPanel button, .InputPanel input {
        border-radius:0;
    }
    .MessagePanel.collapsed {
        display:none;
    }
    .MessagePanel.expanded {
        background-color:#FFFFFF;
        position:fixed;
        left:0;right:0;
        top:64px;bottom:0;
    }
    .MessagePanel.expanded .MessagePanelHeader {
        display:block;
        position:fixed;
        left:0;right:0;
        top:64px;height:48px;
        background-color:#FFFFFF;
        padding:12px 18px;
        cursor:pointer;
    }
    .MessagePanel.expanded .MessagePanelHeader p {
        line-height:24px;
        font-size:20px;
        text-align:right;
    }
    .MessagePanel.expanded .ConversationPanel {
        position:fixed;
        left:0;right:0;
        top:48px;bottom:0;
        border-top: 1px solid #D3D6E9;
        padding-top: 8px !important;
    }
    .MessagePanel.expanded .ConversationPanel .childrow {
        overflow:hidden;
        overflow-y:scroll;
    }
}