@import url('https://fonts.googleapis.com/css?family=Cabin:400,400i,500,500i,600,600i,700,700i|M+PLUS+Rounded+1c:100,300,400,500,700,800,900&display=swap');

a { color:#555555;text-decoration:none; }
a:visited { color:#555555;text-decoration:none; }
a:hover { color:#555555;text-decoration:none; }
a.simple { text-decoration:none; }
a.link { color:#999999;border-bottom:1px dashed #999999;text-decoration:none; }
a.primary { font-weight:bold;color:#95D555;border-bottom:1px dashed #95D555;text-decoration:none; }
button.btn-primary { color: #fff; background-color: #95D555; border-color: #95D555; }
button.btn-primary:disabled  { color: #ddd; background-color: #aaaaaa; border-color: #aaaaaa; }
button.btn-secondary { color: #fff; background-color: #aaaaaa; border-color: #aaaaaa; }
button:focus, input:focus { outline:none !important;box-shadow:none !important; }
.badge { font-size:100%; }
.badge.badge-primary { background-color: #95D555; border-color: #95D555; }
.light { color:#999999 }
.smaller { font-size:87%; }
.compact { margin:0; }

p, button {
	font-family: "M PLUS Rounded 1c", "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #555555;
}
p.small { font-size: 11px }
p.medium { font-size: 14px }
p.right { text-align:right; }
h2 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #555555;
}
h3 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #777777;
}
h4 { 
	font-family: Cabin, "Helvetica-Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	color: #777777; 
}
h4.primary { color:#95D555; }
.Content {
	padding:18px 18px 24px;
}

@media (max-width: 767px) {
    .Content {
        padding:9px;
	}
}