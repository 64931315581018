.DashboardContainer {
	width:100%;
	padding:0;
}

/* media queries */
@media (max-width: 767px) {
	.DashboardContainer {
		width:100%;
		/*max-width:100%;*/
		padding:0;
	}
}